import { FunctionComponent, useEffect, useState } from 'react'

export const NoSSR: FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
    const [mounted, setMount] = useState(false)

    useEffect(() => {
        setMount(true)
    }, [])

    return <>{mounted ? children : null}</>
}
